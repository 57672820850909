import { SettingsTabsIDs } from '@pages/settings/Settings';

export const DefinedRoutes = {
    overview: {
        name: 'overview',
        path: '/overview',
    },
    realTime: {
        name: 'realtime',
        path: '/realtime',
    },
    audience: {
        name: 'audience',
        path: '/audience',

        overview: {
            name: 'overview',
            path: '/audience/overview',
        },
        countries: {
            name: 'countries',
            path: '/audience/countries',
        },
        country: { // single country
            name: 'country',
            path: '/audience/country',
        },
        devices: {
            name: 'devices',
            path: '/audience/devices',
        },
        browsers: {
            name: 'browsers',
            path: '/audience/browsers',
        },
        acquisition: {
            name: 'acquisition',
            path: '/audience/acquisition',
        },
    },
    pages: {
        name: 'content',
        path: '/content',

        overview: {
            name: 'overview',
            path: '/content/overview',
        },
        articlesAndPages: {
            name: 'articles-and-pages',
            path: '/content/articles-and-pages',
        },
        categories: {
            name: 'categories',
            path: '/content/categories',
        },
        authors: {
            name: 'authors',
            path: '/content/authors',
        },

        // single pages
        page: {
            name: 'page',
            path: '/content/page',
        },
        category: {
            name: 'category/:category',
            path: '/content/category/:category',
            slug: ':category'
        },
        author: {
            name: 'author/:author',
            path: '/content/author/:author',
            slug: ':author'
        },
    },
    mentions: {
        name: 'mentions',
        path: '/mentions',
    },
    socialMedia: {
        name: 'social-media',
        path: '/social-media',

        // social media single
        details: {
            name: 'details/:type/:profileId',
            path: '/social-media/details/:type/:profileId',
            slug: [':type', ':profileId']
        }
    },
    settings: {
        name: 'settings',
        path: '/settings',

        tabs: {
            profile: {
                name: SettingsTabsIDs.profile,
                path: `/settings?tab=${SettingsTabsIDs.profile}`
            },
            socials: {
                name: SettingsTabsIDs.socials,
                path: `/settings?tab=${SettingsTabsIDs.socials}`
            },
        }
    },
} as const;

import { ComponentProps, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import TabsView, { TabsViewItemType } from '@components/common/navs/tabs-view/TabsView';
import { House } from '@components/svg/common';
import ProfileSettings from '@features/profile/profile-settings/ProfileSettings';
import SocialMediaSettings from '@features/social-media/settings/SocialMediaSettings';
import useSidebar from '@hooks/sidebar/useSidebar';
import PageWrapper from '@pages/base/PageWrapper';

import './Settings.scss';

export enum SettingsTabsIDs {
    profile = 'profile',
    socials = 'socials',
    // impactScore = 'impact-score',
    // users = 'users',
    // plan = 'plan',
}
const navItems: TabsViewItemType[]                   = [
    {
        title: 'Profile',
        id: SettingsTabsIDs.profile,
    },
    {
        title: 'Social media',
        id: SettingsTabsIDs.socials,
    },
    // {
    //     title: 'Impact score',
    //     id: SettingsTabsIDs.impactScore,
    // },
    // {
    //     title: 'Users',
    //     id: SettingsTabsIDs.users,
    // },
    // {
    //     title: 'Plan',
    //     id: SettingsTabsIDs.plan,
    // },
];

type SettingViews = { [id in SettingsTabsIDs]: (Props?: ComponentProps<any>) => JSX.Element };
const settingViews: SettingViews = {
    [SettingsTabsIDs.profile]: ProfileSettings,
    [SettingsTabsIDs.socials]: SocialMediaSettings,
    // [SettingsTabsIDs.impactScore]: () => <p>impactScore</p>,
    // [SettingsTabsIDs.users]: () => <p>users</p>,
    // [SettingsTabsIDs.plan]: () => <p>plan</p>,
};

export default function Settings() {
    const [searchParams, setSearchParams] = useSearchParams({ tab: SettingsTabsIDs.profile });
    
    const activeTab                       = (()=>{
        const tab = searchParams.get('tab') as SettingsTabsIDs;

        if (!tab  || !Object.values(SettingsTabsIDs).includes(tab)) {
            setSearchParams({ tab: SettingsTabsIDs.profile });

            return SettingsTabsIDs.profile;
        }

        return tab;
    })();

    const sidebar   = useSidebar();


    useEffect(() => {
        if(sidebar.state.sideNavStatus === true) {
            sidebar.toggleStatus(false, 'desktop');
        }

        // return () => {
        //     sidebar.resetSideNavStatus();
        // };
    }, []);

    return (
        <PageWrapper
            wrapperId="settings-wrapper"
            pageTitle={'settings'}
            shouldHideFilters
        >
            <TabsView
                className="settings-menu"
                navItems={navItems}
                navViews={settingViews}
                headerIcon={<House />}
                headerText='General settings'
                initialActiveItemId={activeTab}
                onActiveItemChange={(id) => {
                    setSearchParams({ tab: id });
                }}
            />
        </PageWrapper>
    );
}

import classNames from 'classnames';
import { gsap } from 'gsap';
import React, { ReactNode, useRef, useState } from 'react';

import { MetricInfo } from '@components/svg';
import Tooltip from '@components/widgets/tooltip/Tooltip';
import { useUpdateGsap } from '@hooks/gsap/useUpdateGsap';

import './ComponentInfo.sass';

declare interface ComponentInfoProps {
    tooltipContent: ReactNode;
    beforeImageChild?: React.ReactNode;
    svgClassName?: string;
    tooltipPosition?: {
        left?: string;
        right?: string;
        top?: string;
        bottom?: string;
    };
    tooltipDirection?: number;
}

const ComponentInfo = ({ beforeImageChild, svgClassName, tooltipPosition, tooltipContent, tooltipDirection = 1 }: ComponentInfoProps) => {
    const [tooltip, triggerTooltip] = useState(false);
    const tooltipRef                = useRef<HTMLDivElement>(null);

    useUpdateGsap(() => {
        gsap.to(tooltipRef.current, {
            display: tooltip ? 'initial' : 'none',
            opacity: tooltip ? 1 : 0,
            y: tooltip ? '0' : '-20%',
            scale: tooltip ? 1 : 0.8,
            ease: 'Expo.easeOut',
        });
    }, { dependencies: [tooltip], scope: tooltipRef });

    return (
        <>
            <div
                className="comp-info-container"
                role="ci-info"
                onMouseEnter={() => triggerTooltip(true)}
                onMouseLeave={() => triggerTooltip(false)}
            >
                {beforeImageChild}
                <div
                    className="comp-info-logo"
                    data-testid="ci-info-logo"
                >
                    <MetricInfo className={svgClassName} />
                </div>

                <div className={classNames('comp-info-tooltip', { reverse: tooltipDirection === -1 })} role="ci-tooltip" ref={tooltipRef}>
                    <Tooltip
                        position={tooltipPosition || { top: '0px', left: '0px' }}
                        content={tooltipContent || 'tooltip empty!'}
                    />
                </div>
            </div>
        </>
    );
};

ComponentInfo.defaultProps = {
    tooltipContent: '',
    beforeImageChild: null,
};

export default ComponentInfo;

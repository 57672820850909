import { isEmpty } from 'lodash';

import type { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import type { SocialMediaEngagement, SocialMediaInteractions, SocialMediaOverviewHighlights, SocialMediaPosts, SocialMediaReach, SocialMediaTotalFollower, SocialMediaTotalFollowers } from '@shared/interfaces/social-media';
import type { SocialMediaOverviewProfile, SocialMediaOverviewProfiles, SocialMediaOverviewReturn } from './socialMediaOverview.thunk';

import { calcPercentageOfCurrentFromPrevious } from '@services/math/math';
import { filterObjectProperties } from '@services/object/object';

export interface SocialMediaOverview {
    profiles: SocialMediaOverviewProfiles;
    highlights: SocialMediaOverviewHighlights;
    engagement: SocialMediaEngagement;
    reach: SocialMediaReach;
    interactions: SocialMediaInteractions;
    followers: SocialMediaTotalFollowers;
    posts: SocialMediaPosts;
}

export function formatSocialMediaOverviewHighlights(data: SocialMediaOverviewReturn): SocialMediaOverview {
    if (isEmpty(data)) {
        return {
            profiles: {},
            highlights: [],
            engagement: {
                chart: [],
                dates: [],
                pastDates: [],
                total: {
                    value: 0,
                    pastValue: null,
                    percentage: 0,
                    diff: 0
                },
            },
            reach: {
                chart: {
                    organic: {
                        data: [],
                        dates: [],
                        pastDates: [],
                        total: 0,
                    },
                    paid: {
                        data: [],
                        dates: [],
                        pastDates: [],
                        total: 0,
                    },
                },
                total: {
                    value: 0,
                    pastValue: null,
                    percentage: 0,
                    diff: 0
                },
            },
            interactions: [],
            followers: [],
            posts: [],
        };
    }

    const engagementProps = filterObjectProperties({
        object: data,
        properties: ['engagement']
    }) as unknown as Pick<SocialMediaOverviewReturn, 'engagement'>;
    const reachProps      = filterObjectProperties({
        object: data,
        properties: ['reach']
    }) as unknown as Pick<SocialMediaOverviewReturn, 'reach'>;

    const noneProfileIDKeys                          = ['engagement', 'reach'];
    const filteredData                               = Object.fromEntries(
        Object.entries(data).filter(([key]) => (
            !noneProfileIDKeys.includes(key)
        ))
    );
    const profilesId                                 = Object.keys(filteredData);
    const profilesData: SocialMediaOverviewProfile[] = Object.values(filteredData);

    const highlights = profilesData.map((profileData, idx) => {
        const value     = Number(profileData.engagement.total ?? 0);
        const pastValue = Number(profileData.precedentEngagement.total ?? 0);

        return {
            profileName: profileData.profileName,
            networkType: profileData.networkType as SocialMediaTypes,
            profileId: profilesId[idx],
            engagement: value,
            pastEngagement: pastValue,
            isEngagementInPercentage: false,
            compare: {
                percentage: calcPercentageOfCurrentFromPrevious(value, pastValue, 1),
                diff: value - pastValue,
            }
        };
    });

    const interactions   = profilesData.map((profileData, idx) => {
        const id     = profilesId[idx];
        const dates  = Object.keys(profileData.interaction?.days ?? {});
        const values = Object.values(profileData.interaction?.days ?? {});
        const chart  = values.map((value) => ({
            value: Number(value),
            pastValue: 0,
            compare: {
                percentage: 0,
                diff: 0
            }
        }));

        return {
            id,
            name: profileData.profileName,
            networkType: profileData.networkType as SocialMediaTypes,
            chart,
            dates: {
                current: dates,
                past: []
            },
            total: profileData.interaction.total
        };
    });

    const nbTotalFollowers = profilesData.reduce((acc, profile) => (acc + Number(profile.totalFollowers)), 0);
    const followers        = profilesData.reduce((acc, next, idx) => {
        const sameNetworkType = acc.find(item => item.networkType === next.networkType);

        if (sameNetworkType) {
            return acc.map((item) => {
                if (item.id === sameNetworkType.id) {
                    const totalFollowers     = Number(item?.totalFollowers ?? 0) + Number(next?.totalFollowers ?? 0);
                    const pastTotalFollowers = Number(item?.pastTotalFollowers ?? 0) + Number(next?.totalPrecedentFollowers ?? 0);
                    const progression        = calcPercentageOfCurrentFromPrevious(totalFollowers, pastTotalFollowers, 2);

                    return {
                        id: `${item?.id}-${profilesId?.[idx] ?? 'N/A'}`,
                        name: `${item?.name} | ${next.profileName}`,
                        networkType: next.networkType as SocialMediaTypes,
                        totalFollowers,
                        pastTotalFollowers,
                        progression,
                        percentage: Number((totalFollowers / nbTotalFollowers * 100).toFixed(2)),
                        compare: {
                            percentage: progression,
                            diff: totalFollowers - pastTotalFollowers
                        }
                    };
                }

                return item;
            });
        }

        const id                 = profilesId[idx];
        const totalFollowers     = Number(next.totalFollowers);
        const pastTotalFollowers = Number(next.totalPrecedentFollowers ?? 0);

        return [
            ...acc,
                {
                    id,
                    name: next.profileName,
                    networkType: next.networkType as SocialMediaTypes,
                    totalFollowers,
                    pastTotalFollowers,
                    progression: Number(next.totalFollowersProgression),
                    percentage: Number((totalFollowers / nbTotalFollowers * 100).toFixed(2)),
                    compare: {
                        percentage: calcPercentageOfCurrentFromPrevious(totalFollowers, pastTotalFollowers, 2),
                        diff: totalFollowers - pastTotalFollowers
                    }
                } satisfies SocialMediaTotalFollower
        ];
    }, [] as SocialMediaTotalFollowers);

    const posts = profilesData.map((profileData, idx) => {
        const id                 = profilesId[idx];

        return {
            id,
            name: profileData.profileName,
            networkType: profileData.networkType as SocialMediaTypes,
            totalPosts: profileData.totalPosts,
            postsPerDay: profileData.postsPerDay,
            engagement: profileData.engagement.total ?? 0,
            reach: profileData.reach.total ?? 0,
        };
    });

    const engagementDates     = Object.keys(engagementProps.engagement.totalEngagementPerDay);
    const engagementValues    = Object.values(engagementProps.engagement.totalEngagementPerDay) ?? [];
    const engagementChart     = engagementValues.map((data) => ({
        value: Number(data.date1),
        pastValue: Number(data.date2),
        compare: {
            percentage: Number(data.percentage),
            diff: Number(data.difference)
        }
    }));
    const engagementPastDates = engagementValues.map((data) => data.comparedDate);

    const organicReachValues    = Object.values(reachProps.reach.totalorganicReachPerDay?.days) ?? [];
    const organicReachChart     = organicReachValues.map((data) => ({
        value: Number(data.date1),
        pastValue: Number(data.date2),
        compare: {
            percentage: Number(data.percentage),
            diff: Number(data.difference)
        }
    }));
    const organicReachDates     = Object.keys(reachProps.reach.totalorganicReachPerDay?.days) ?? [];
    const organicReachPastDates = organicReachValues.map((data) => data.comparedDate);
    const paidReachValues       = Object.values(reachProps.reach.totalpaidReachPerDay?.days) ?? [];
    const paidReachChart        = paidReachValues.map((data) => ({
        value: Number(data.date1),
        pastValue: Number(data.date2),
        compare: {
            percentage: Number(data.percentage),
            diff: Number(data.difference)
        }
    }));
    const paidReachDates        = Object.keys(reachProps.reach.totalpaidReachPerDay?.days) ?? [];
    const paidReachPastDates    = paidReachValues.map((data) => data.comparedDate);

    return {
        profiles: filterObjectProperties<SocialMediaOverviewReturn>({
            object: data,
            properties: ['engagement', 'reach'],
            reverse: true
        }),
        highlights,
        engagement: {
            chart: engagementChart,
            dates: engagementDates,
            pastDates: engagementPastDates ?? [],
            total: {
                value: Number(engagementProps.engagement?.total || 0),
                pastValue: Number(engagementProps.engagement?.totalCompared || 0),
                percentage: Number(engagementProps.engagement?.percentage || 0),
                diff: Number(engagementProps.engagement?.difference || 0),
            },
        },
        reach: {
            chart: {
                organic: {
                    data: organicReachChart,
                    dates: organicReachDates,
                    pastDates: organicReachPastDates ?? [],
                    total: Number(reachProps.reach.totalorganicReachPerDay.total || 0),
                },
                paid: {
                    data: paidReachChart,
                    dates: paidReachDates,
                    pastDates: paidReachPastDates,
                    total: Number(reachProps.reach.totalpaidReachPerDay.total || 0),
                },
            },
            total: {
                value: Number(reachProps.reach?.total || 0),
                pastValue: Number(reachProps.reach?.totalCompared || 0),
                percentage: Number(reachProps.reach?.percentage || 0),
                diff: Number(reachProps.reach?.difference || 0),
            },
        },
        interactions,
        followers,
        posts,
    };
}

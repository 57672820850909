export const Mentions = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_3681_965)">
            <path d="M2.725 12.5C2.725 7.10133 7.10133 2.725 12.5 2.725C17.8987 2.725 22.275 7.10133 22.275 12.5C22.275 15.4532 20.7599 16.5319 19.6783 16.5428C18.618 16.5543 17.1 15.5337 17.1 12.5V7.6125C17.1 7.38375 17.0091 7.16437 16.8474 7.00262C16.6856 6.84087 16.4662 6.75 16.2375 6.75C16.0088 6.75 15.7894 6.84087 15.6276 7.00262C15.4659 7.16437 15.375 7.38375 15.375 7.6125V8.2105C14.4734 7.30948 13.2762 6.75 11.925 6.75C8.96835 6.75 6.75 9.4295 6.75 12.5C6.75 15.5705 8.96835 18.25 11.925 18.25C13.6828 18.25 15.1795 17.303 16.1053 15.889C16.8798 17.4185 18.1982 18.2833 19.6967 18.2678C22.0651 18.2431 24 16.0161 24 12.5C24 6.14855 18.8514 1 12.5 1C6.14855 1 1 6.14855 1 12.5C1 18.8514 6.14855 24 12.5 24C13.9202 24 15.2824 23.7418 16.5405 23.2697C16.6489 23.2317 16.7486 23.1725 16.8337 23.0954C16.9189 23.0183 16.9878 22.9251 17.0364 22.821C17.085 22.717 17.1123 22.6043 17.1168 22.4895C17.1213 22.3748 17.1028 22.2603 17.0625 22.1528C17.0221 22.0453 16.9607 21.9469 16.8818 21.8634C16.8029 21.78 16.7082 21.7131 16.6031 21.6668C16.4981 21.6204 16.3848 21.5955 16.27 21.5935C16.1551 21.5915 16.0411 21.6125 15.9345 21.6551C14.8362 22.0666 13.6728 22.2766 12.5 22.275C7.10133 22.275 2.725 17.8987 2.725 12.5ZM15.375 12.5C15.375 14.8276 13.7322 16.525 11.925 16.525C10.1178 16.525 8.475 14.8276 8.475 12.5C8.475 10.1724 10.1178 8.475 11.925 8.475C13.7322 8.475 15.375 10.1724 15.375 12.5Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_3681_965">
                <rect width="24" height="24" fill="white" transform="translate(0 -0.000488281)"/>
            </clipPath>
        </defs>
    </svg>
);

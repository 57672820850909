import classNames from 'classnames';
import { Fragment, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import { Logout } from '@components/svg/Logout';
import { Cube, Indepth } from '@components/svg/indepth';
import { Audience, Content, Mentions, Overview, Realtime, Report, Settings, SocialMedia } from '@components/svg/pages';
import { getIsTablet } from '@constants/media';
import { useAppDispatch, useAppSelector } from '@hooks/redux/useRedux';
import { DefinedRoutes } from '@routes/definedRoutes';
import { isInAudiencePage, isInContentPage } from '@services/validation/validation';
import { logoutUser } from '@store/authentication/authentication.actions';

import { useGetUserSelectedSite } from '@hooks/index';
import { getIsClientAllowedBetaFeatures } from '@services/feature-validation/featureValidation';
import { selectedFromUser } from '@store/authentication/authentication.reducer';
import { AppNavGroup, type AppNavItemProps } from './';
import './AppNav.sass';

export declare interface NavProps {
    sidebarStatus: boolean;
    logoutUser?: any;
}

const AppNav = ({ sidebarStatus }: NavProps) => {
    const dispatch       = useAppDispatch();
    const size           = useWindowSize();
    const location       = useLocation();
    const { pathname }   = location;
    const { hasAuthors } = useAppSelector(selectedFromUser.settings);

    const switchToMobile = useMemo(() => getIsTablet(), [size.width, size.height]);

    const navClass: string = !sidebarStatus && !switchToMobile ? 'collapsed' : '';
    const isCollapsed      = !sidebarStatus && !switchToMobile;

    const selectedSite   = useGetUserSelectedSite();
    const hasBetaFeature = getIsClientAllowedBetaFeatures(selectedSite?.name);

    const navGroups: {
        groupName: string;
        groupItems: (Omit<AppNavItemProps, 'isCollapsed'> & { validator?: () => boolean })[];
    }[] = [
        {
            groupName: 'CONTENT',
            groupItems: [
                {
                    icon: <Overview />,
                    name: 'Overview',
                    route: DefinedRoutes.overview.path
                },
                {
                    icon: <Realtime />,
                    name: 'Real time',
                    route: DefinedRoutes.realTime.path
                },
                {
                    icon: <Audience />,
                    name: 'Audience',
                    onClick: () => null,
                    isItemActive: isInAudiencePage(pathname),
                    validator: () => isInAudiencePage(pathname),
                    dropdownItems: [
                        {
                            title: 'Overview',
                            isLink: true,
                            path: DefinedRoutes.audience.overview.path,
                        },
                        {
                            title: 'Countries',
                            isLink: true,
                            path: DefinedRoutes.audience.countries.path,
                        },
                        {
                            title: 'Devices',
                            isLink: true,
                            path: DefinedRoutes.audience.devices.path,
                        },
                        {
                            title: 'Browsers',
                            isLink: true,
                            path: DefinedRoutes.audience.browsers.path,
                        },
                        {
                            title: 'Acquisition',
                            isLink: true,
                            path: DefinedRoutes.audience.acquisition.path,
                        },
                    ]
                },
                {
                    icon: <Content />,
                    name: 'Content',
                    onClick: () => null,
                    isItemActive: isInContentPage(pathname),
                    validator: () => isInContentPage(pathname),
                    dropdownItems: [
                        {
                            title: 'Overview',
                            isLink: true,
                            path: DefinedRoutes.pages.overview.path,
                        },
                        {
                            title: 'Articles and pages',
                            isLink: true,
                            path: DefinedRoutes.pages.articlesAndPages.path,
                        },
                        {
                            title: 'Categories',
                            isLink: true,
                            path: DefinedRoutes.pages.categories.path,
                        },
                        hasAuthors && hasBetaFeature ? {
                            title: 'Authors',
                            isLink: true,
                            path: DefinedRoutes.pages.authors.path,
                        } : null,
                    ].filter(Boolean)
                },
                {
                    icon: <Report />,
                    name: 'Report',
                    isDisabled: true,
                    onClick: () => null
                },
            ]
        },
        {
            groupName: 'PRODUCTS',
            groupItems: [
                {
                    icon: <SocialMedia />,
                    name: 'Social Media',
                    route: DefinedRoutes.socialMedia.path
                },
                {
                    icon: <Mentions />,
                    name: 'Mentions',
                    route: DefinedRoutes.mentions.path
                },
            ]
        },
        {
            groupName: 'ADMIN',
            groupItems: [
                {
                    icon: <Settings />,
                    name: 'Settings',
                    route: DefinedRoutes.settings.path
                },
                {
                    icon: <Logout />,
                    name: 'Logout',
                    onClick:  () => {
                        dispatch(logoutUser());
                    }
                },
            ]
        }
    ];

    return (
        <nav data-testid="nav-container" className={`nav-wrapper ${navClass}`}>
            <div className="nav-indepth-logo-wrapper">
                <NavLink to={DefinedRoutes.overview.path}>
                    <Cube className={classNames('indepth-cube-logo')} />
                    <Indepth className={classNames('indepth-logo')} />
                </NavLink>
            </div>

            <div className="app-nav-groups">
                {navGroups.map((groupProps, idx) => (
                    <Fragment key={`${groupProps.groupName}-${idx}`}>
                        <AppNavGroup
                            isCollapsed={isCollapsed}
                            {...groupProps}
                        />
                        {idx !== navGroups.length-1 && <div className="app-nav-groups-separator" />}
                    </Fragment>
                ))}
            </div>
        </nav>
    );
};

export default AppNav;

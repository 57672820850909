import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DefaultPageSkeletonBuilder from '@components/skeletons/default-page-skeleton/DefaultPageSkeletonBuilder';
import PageSuspense from '@components/skeletons/page-suspense/PageSuspense';
import AudienceSkeletonBuilder from '@pages/audience/skeleton/AudienceSkeletonBuilder';
import NotFound from '@pages/not-found/NotFound';
import RealtimeSkeletonBuilder from '@pages/realtime/skeleton/RealtimeSkeletonBuilder';

import AppRoutesBoundary from './boundary/AppRoutesBoundary';
import { DefinedRoutes } from './definedRoutes';
import PublicOutlet from './validator/PublicOutlet';
import withAuth from './with-auth/withAuth';

const Overview           = lazy(() => import('pages/overview/Overview'));
const Realtime           = lazy(() => import('pages/realtime/Realtime'));
const Audience           = lazy(() => import('pages/audience/Audience'));
const Content            = lazy(() => import('pages/content/Content'));
const Mentions           = lazy(() => import('pages/mentions/Mentions'));
const Settings           = lazy(() => import('pages/settings/Settings'));
const SocialMedia        = lazy(() => import('pages/social-media/SocialMedia'));
const SocialMediaDetails = lazy(() => import('pages/social-media/details/SocialMediaDetails'));

const AppRoutes = (routeProps) => (
    <AppRoutesBoundary>
        {/* ↓ this top level suspense is here in case a page has no fallback loader */}
        <Suspense fallback={<PageSuspense SkeletonBuilder={DefaultPageSkeletonBuilder}/>}>
            <Routes>
                <Route path="/" element={<Navigate to={DefinedRoutes.overview.path} />}/>

                <Route path= "" element={<PublicOutlet />}>
                    {/* overview page */}
                    <Route index path={DefinedRoutes.overview.name} element={<Overview {...routeProps} />} />

                    {/* realtime page */}
                    <Route
                        path={DefinedRoutes.realTime.name}
                        element={
                            <Suspense fallback={<PageSuspense SkeletonBuilder={RealtimeSkeletonBuilder}/>}>
                                <Realtime {...routeProps} />
                            </Suspense>
                        }
                    />

                    {/* audience pages */}
                    <Route
                        path={DefinedRoutes.audience.name}
                        element={<Navigate to={DefinedRoutes.audience.overview.name} />}
                    />
                    <Route
                        path={`${DefinedRoutes.audience.name}/*`}
                        element={
                            <Suspense fallback={<PageSuspense SkeletonBuilder={AudienceSkeletonBuilder}/>}>
                                <Audience {...routeProps} />
                            </Suspense>
                        }
                    />

                    {/* content pages */}
                    <Route
                        path={DefinedRoutes.pages.name}
                        element={<Navigate to={DefinedRoutes.pages.overview.name} />}
                    />
                    <Route
                        path={`${DefinedRoutes.pages.name}/*`}
                        element={
                            <Suspense fallback={<PageSuspense SkeletonBuilder={AudienceSkeletonBuilder}/>}>
                                <Content {...routeProps} />
                            </Suspense>
                        }
                    />

                    {/* mentions */}
                    <Route path={DefinedRoutes.mentions.path} element={<Mentions {...routeProps} />} />

                    {/* social media */}
                    <Route path={DefinedRoutes.socialMedia.path} element={<SocialMedia {...routeProps} />}/>
                    <Route path={DefinedRoutes.socialMedia.details.path} element={<SocialMediaDetails {...routeProps} />}/>

                    {/* settings */}
                    <Route path={DefinedRoutes.settings.path} element={<Settings {...routeProps} />} />
                </Route>

                <Route path="*" element={<NotFound />}/>
            </Routes>
        </Suspense>
    </AppRoutesBoundary>
);

export default withAuth(AppRoutes);

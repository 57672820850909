import React, { forwardRef, ReactNode } from 'react';

import type { CssPosition } from 'shared/interfaces/position';

import './Tooltip.sass';

declare interface TooltipProps {
    content: ReactNode;
    style?: {
        wrapper?: React.CSSProperties;
        paragraph?: React.CSSProperties;
    };
    position?: CssPosition;
}

const Tooltip = forwardRef<React.Ref<any>, TooltipProps>((
    { content, style, position },
    ref
) => {
    const wrapper: React.CSSProperties   =
        style && style?.wrapper ? style.wrapper : {};
    const paragraph: React.CSSProperties =
        style && style?.paragraph ? style.paragraph : {};
    const wrapperStyle                   = {
        top: position?.top || '',
        bottom: position?.bottom || '',
        right: position?.right || '',
        left: position?.left || '',
        ...wrapper,
    };
    const containsList                   = content.toString().includes('<ul>');

    return (
        <div
            data-testid="tp-wrapper"
            className="tooltip-wrapper"
            style={wrapperStyle}
            ref={ref}
        >
            <p
                data-testid="tp-content"
                style={{ ...paragraph, whiteSpace: containsList ? 'unset' : 'break-spaces' }}
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    );
});

export default Tooltip;

import { createSlice } from '@reduxjs/toolkit';

import { DataStatus } from '@shared/enum/dataStatus';
import { type ImpactScore } from '@shared/interfaces/store/impactScore';

import { RootState } from '@store/storeConfig';
import { fetchImpactScore } from './impactScore.thunk';

export declare interface ImpactScoreState {
    score: ImpactScore;
    error: string | null;
    status: DataStatus;
}

const initialState: ImpactScoreState = {
    score: null,
    error: null,
    status: DataStatus.IDLE,
};

export const impactScoreSlice = createSlice({
    name: 'impactScore',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchImpactScore.pending, (state) => {
                state.status = DataStatus.LOADING;
            })
            .addCase(fetchImpactScore.fulfilled, (state, action) => {
                state.score  = action.payload;
                state.status = DataStatus.SUCCESS;
            })
            .addCase(fetchImpactScore.rejected, (state, action) => {
                state.error  = action.payload as unknown as string;
                state.status = DataStatus.FAILED;
            });
    },
});

export const {
    clearState
} = impactScoreSlice.actions;
export const selectedFromImpactScore = {
    score: (state: RootState) => ({
        score: state.impactScoreReducer.score ?? null,
        error: state.impactScoreReducer.error,
        status: state.impactScoreReducer.status,
    })
};
const impactScoreReducer = impactScoreSlice.reducer;

export default impactScoreReducer;

declare interface API {
    apiDomain: string;
    loginUrl: string;
    inkyScrapper: string;
    authorsMicro: string;
    countriesMicro: string;
    loyalVisitorsMicro: string;
    socialMediaApiDomain: string;
    authMicro: string;
    liveMicro: string;
}

class ConfigService {
    private static _instance: ConfigService;

    private constructor(){
        if (!Object.keys(import.meta.env).length) {
            throw new Error('Environment is empty!');
        }
    }

    static get Instance() {
        if (!ConfigService._instance) {
            ConfigService._instance = new ConfigService();
        }

        return ConfigService._instance;
    }

    get isDevelopment(): boolean {
        return import.meta.env.MODE === 'development';
    }

    get isProduction(): boolean {
        return import.meta.env.MODE === 'production';
    }

    get search(): { preventedKeywords: string } {
        return {
            preventedKeywords: import.meta.env.VITE_SEARCH_PREVENTED_KEYWORDS
        };
    }

    get api(): API {
        return {
            apiDomain: import.meta.env.VITE_INDEPTH_API_DOMAIN,
            loginUrl: import.meta.env.VITE_LOGIN_URL,
            inkyScrapper: import.meta.env.VITE_INDEPTH_SCRAPPER_BASE_DOMAIN,
            socialMediaApiDomain: import.meta.env.VITE_INDEPTH_SOCIAL_BASE_DOMAIN,
            authorsMicro: import.meta.env.VITE_INDEPTH_AUTHORS_MICRO_BASE_DOMAIN,
            countriesMicro: import.meta.env.VITE_INDEPTH_COUNTRIES_MICRO_BASE_DOMAIN,
            loyalVisitorsMicro: import.meta.env.VITE_INDEPTH_LOYAL_VISITORS_MICRO_BASE_DOMAIN,
            authMicro: import.meta.env.VITE_INDEPTH_AUTH_API_MICRO_BASE_DOMAIN,
            liveMicro: import.meta.env.VITE_INDEPTH_LIVE_API_MICRO_BASE_DOMAIN
        };
    }

    get app(): { domain: string } {
        return {
            domain: import.meta.env.VITE_INDEPTH_APP_BASE_DOMAIN,
        };
    }
}

const Config = ConfigService.Instance;

export default Config;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { combineReducers } from 'redux';
import acquisitionReducer from './acquisition/acquisition.reducer';
import allContentReducer from './all-content/allContent.reducer';
import authenticationReducer from './authentication/authentication.reducer';
import authorReducer from './author/author.reducer';
import browsersReducer from './browsers/browsers.reducer';
import categoriesReducer from './categories/categories.slice';
import cleanerReducer from './cleaner/cleaner.reducer';
import { CLEANER_CLEAN_REDUCERS, CLEANER_CLEAN_STORE } from './cleaner/cleaner.types';
import conversionsReducer from './conversions/conversions.reducer';
import countriesReducer from './countries/countries.reducer';
import countryReducer from './countries/country.slice';
import datePickerReducer from './date-picker/datePicker.reducer';
import depthRateReducer from './depthRate/depthRate.reducer';
import devicesReducer from './devices/devices.reducer';
import genderAgeReducer from './gender-age/genderAge.reducer';
import impactScoreReducer from './impact-score/impactScore.slice';
import inHeatReducer from './indepth-and-heatmap/inheat.reducer';
import languagesReducer from './languages/languages.reducer';
import liveReducer from './live/live.slice';
import overviewMentionsReducer from './mentions/mentions.slice';
import pageDownloadReducer from './page-download/pageDownload.slice';
import sideBarReducer from './sideBar/sideBar.reducer';
import singleMetaReducer from './single-meta/singleMeta.reducer';
import singleSearchReducer from './single-search/singleSearch.reducer';
import siteLoaderReducer from './site-loader/siteLoader.reducer';
import socialMediaOverviewReducer from './social-media/overview/socialMediaOverview.slice';
import socialMediaSingleReducer from './social-media/single/SocialMediaSingle.slice';
import socialMediaSettingsReducer from './social-media/social-media-settings/socialMediaSettings.slice';
import topContentReducer from './top-content/topContent.reducer';
import viewsReducer from './views/views.reducer';
import visitorBehaviorReducer from './visitor-behavior/visitorBehavior.reducer';
import visitorsPerHourReducer from './visitors-per-hour/visitorsPerHour.reducer';

const appReducer = combineReducers({
    pageDownloadReducer,
    socialMediaSettingsReducer,
    socialMediaOverviewReducer,
    socialMediaSingleReducer,
    acquisitionReducer,
    languagesReducer,
    categoriesReducer,
    viewsReducer,
    devicesReducer,
    datePickerReducer,
    visitorsPerHourReducer,
    countriesReducer,
    countryReducer,
    topContentReducer,
    depthRateReducer,
    authorReducer,
    liveReducer,
    inHeatReducer,
    visitorBehaviorReducer,
    browsersReducer,
    singleMetaReducer,
    siteLoaderReducer,
    singleSearchReducer,
    impactScoreReducer,
    authenticationReducer,
    sideBarReducer,
    genderAgeReducer,
    conversionsReducer,
    allContentReducer,
    cleanerReducer,
    overviewMentionsReducer
});

/**
 * to avoid the circular dependency issue, we created this type RootReducer to
 * use intead of RootState inside this file only.
 */
type RootReducer = ReturnType<typeof appReducer>;

const rootReducer = (state: RootReducer, action): RootReducer => {
    // clean store when user logged out
    if (action.type === 'SET_CURRENT_USER' && !state.authenticationReducer.isAuthenticated){
        return appReducer(undefined, action);
    }

    const toBeConservedReducerKeys = [
        'authenticationReducer',
        'sideBarReducer',
        'datePickerReducer',
        'siteLoaderReducer'
    ];

    // clean store on cleanup action
    if (action.type === CLEANER_CLEAN_STORE || action.type === CLEANER_CLEAN_REDUCERS) {
        const clearableReducers       = state.cleanerReducer.reducers;
        const shouldCheckForClearable = clearableReducers && clearableReducers?.length;

        const reducers         = appReducer(state, action);
        const filteredReducers = Object.fromEntries(
            Object.entries(reducers).map(([key, value]) => {
                if (shouldCheckForClearable) {
                    if ((clearableReducers as string []).includes(key)) {
                        return [key, {}];
                    }

                    return [key, value];
                }

                if (toBeConservedReducerKeys.includes(key)){
                    return [key, value];
                }

                return [key, {}];

            })
        );

        return filteredReducers as RootReducer;
    }

    return appReducer(state, action);
};

// @ts-ignore
export default rootReducer;

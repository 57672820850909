import { useGSAP } from '@gsap/react';
import { useLocation } from 'react-router-dom';

import { gsap } from '@services/gsap';
import { getIsTablet } from 'constants/media';

import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';
import useSidebar from './useSidebar';

export default function useAnimateSidebar<E = HTMLDivElement>(container: React.MutableRefObject<E>){
    const location     = useLocation();
    const sidebar      = useSidebar();
    const size         = useWindowSize();
    const switchToMobile = useMemo(() => getIsTablet(), [size.width, size.height]);
    const sidebarState = sidebar?.state;

    const selector: gsap.utils.SelectorFunc = gsap.utils.selector(container);

    useGSAP(() => {
        if (sidebar.type === 'desktop' && !switchToMobile){
            resetMobileSidebarAnimation(selector);

            // animate desktop sidebar
            !sidebarState.sideNavStatus
                ? animateSidebarIn(selector)
                : animateSidebarOut(selector);
        } else if (sidebar.type === 'mobile') {
            // animate mobile sidebar
            !sidebarState.mobileSideNavStatus
                ? animateMobileSidebarOut(selector)
                : animateMobileSidebarIn(selector);
        }
    }, { dependencies: [sidebarState, switchToMobile], scope: container });

    useGSAP(() => {
        if (sidebar.type === 'mobile') {
            animateMobileSidebarOut(selector, 0.3);
        }
    }, { dependencies: [location], scope: container });


    return true;
}

function resetMobileSidebarAnimation(selector: gsap.utils.SelectorFunc) {
    gsap
        .set(selector('.app-nav'), {
            duration: '',
            opacity: '',
            display: '',
            x: '',
            ease: '',
        });
}

function animateMobileSidebarIn(selector: gsap.utils.SelectorFunc){
    const timeline = gsap.timeline();

    timeline
        .to(selector('.app-nav'), {
            duration: .1,
            display: 'initial',
            onUpdate: () => gsap.to('.app-nav', {
                opacity: 1,
                x: 0,
                ease: 'expo.inOut',
                duration: .3,
            })
        });
}

function animateMobileSidebarOut(selector: gsap.utils.SelectorFunc, delay = 0){
    const timeline = gsap.timeline();

    timeline
        .to(selector('.app-nav'), {
            duration: .3,
            delay,
            opacity: 0,
            display: 'none',
            x: -50,
            ease: 'expo.inOut',
        });
}


function animateSidebarIn(selector: gsap.utils.SelectorFunc){
    const tl = gsap.timeline();

    tl
        .to(selector('.nav-indepth-logo-wrapper>a, .app-nav-item-wrapper, .app-nav-group>p, .app-nav-groups-separator'), {
            y: -20,
            opacity: 0,
            stagger: .02,
            duration: .1
        })
        .to(selector('.app-nav, .inner-nav'), {
            width: '4rem',
            duration: .3,
            onComplete: () => {
                // hide all text & dropdown, only show icons
                gsap.set(selector('.app-nav-group.collapsed>p, .app-nav-item.collapsed p'), {
                    display: 'none'
                });
                gsap.set(selector('.app-nav-item.collapsed svg'), {
                    width: '30px',
                    margin: 0
                });
                gsap.set(selector('.app-nav-item-wrapper.collapsed .app-nav-item-toggle'), {
                    display: 'none'
                });
                gsap.set(selector('.app-nav-item.collapsed'), {
                    justifyContent: 'center'
                });
                // switch indepth app icon
                gsap.set(selector('.nav-indepth-logo-wrapper'), {
                    display: 'grid',
                    placeItems: 'center'
                });
                gsap.set(selector('.nav-indepth-logo-wrapper .indepth-logo'), {
                    display: 'none'
                });
                gsap.set(selector('.nav-indepth-logo-wrapper .indepth-cube-logo'), {
                    display: 'initial'
                });
            }
        }, '-=.1')
        .to(selector('.nav-collapse'), {
            rotate: '180deg',
            duration: .3
        }, '-=.5')
        .to(selector('.app-wrapper'), {
            marginLeft: '2.5rem'
        }, '-=.3')
        .to(selector('.nav-indepth-logo-wrapper>a, .app-nav-item-wrapper, .app-nav-group>p, .app-nav-groups-separator'), {
            y: 0,
            opacity: 1,
            stagger: .02,
            duration: .1
        });
}

function animateSidebarOut(selector: gsap.utils.SelectorFunc){
    const tl = gsap.timeline();

    tl
        .to(selector('.nav-indepth-logo-wrapper>a, .app-nav-item-wrapper, .app-nav-group>p, .app-nav-groups-separator'), {
            y: -20,
            opacity: 0,
            stagger: .02,
            duration: .1,
            onComplete: () => {
                // revert => hide all text & dropdown, only show icons
                gsap.set(selector('.app-nav-group:not(.collapsed)>p, .app-nav-item:not(.collapsed) p'), {
                    display: 'initial'
                });
                gsap.set(selector('.app-nav-item:not(.collapsed) svg'), {
                    width: 'initial',
                    margin: ''
                });
                gsap.set(selector('.app-nav-item-wrapper:not(.collapsed) .app-nav-item-toggle'), {
                    display: 'grid'
                });
                gsap.set(selector('.app-nav-item:not(.collapsed)'), {
                    justifyContent: 'flex-start'
                });
                // reset ==> switch indepth app icon
                gsap.set(selector('.nav-indepth-logo-wrapper'), {
                    display: 'initial',
                    placeItems: ''
                });
                gsap.set(selector('.nav-indepth-logo-wrapper .indepth-logo'), {
                    display: 'initial'
                });
                gsap.set(selector('.nav-indepth-logo-wrapper .indepth-cube-logo'), {
                    display: 'none'
                });
            }
        })
        .to(selector('.app-nav, .inner-nav'), {
            width: '12rem',
            duration: .3,
        }, '-=.1')
        .to(selector('.nav-collapse'), {
            rotate: '0deg',
            duration: .3
        }, '-=.5')
        .to(selector('.app-wrapper'), {
            marginLeft: '4rem'
        }, '-=.3')
        .to(selector('.nav-indepth-logo-wrapper>a, .app-nav-item-wrapper, .app-nav-group>p, .app-nav-groups-separator'), {
            y: 0,
            opacity: 1,
            stagger: .02,
            duration: .1
        });
}

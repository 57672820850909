/* eslint-disable no-case-declarations */
import { getErrorMessage } from '@services/error-handler/message';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const availableTypes = ['default', 'info', 'success', 'warn', 'error'];
const defaultOptions = {};

/**
 *
 * @param opts {import('react-toastify').ToastOptions}
 */
export default function useToast(opts = defaultOptions) {
    /**
     *
     * @param {String} text
     * @param {'default' | 'info' | 'success' | 'warn' | 'error'} type - available types: default | info | success | warn | error
     * @param options {import('react-toastify').ToastOptions}
     */
    const triggerToast = (text, type = 'default', options = opts) => {
        if (!availableTypes.includes(type)) {
            console.error(
                'toast type unknown, please use one of the available types: default | info | success | warn | error',
            );
        }

        switch (type) {
            case 'info':
                customToast.info(text, { toastId: Math.random() * 1987501984, ...options });

                break;

            case 'success':
                customToast.success(text, { toastId: Math.random() * 1987501984, ...options });

                break;

            case 'warn':
                customToast.warn(text, { toastId: Math.random() * 1987501984, ...options });

                break;

            case 'error':
                const message = getErrorMessage(text);

                customToast.error(message, { toastId: Math.random() * 1987501984, ...options });

                break;

            default:
                toast(text, { toastId: Math.random() * 1987501984, ...options });

                break;
        }
    };

    return { triggerToast };
}

export const customToast = {
    success(msg, options = {}) {
        return toast.success(msg, {
            ...defaultOptions,
            ...options,
            className: 'toast-success-container toast-success-container-after',
        });
    },
    error(msg, options = {}) {
        return toast.error(msg, {
            ...defaultOptions,
            ...options,
            className: 'toast-error-container toast-error-container-after',
        });
    },
    warn(msg, options = {}) {
        return toast.warn(msg, {
            ...defaultOptions,
            ...options,
            className: 'toast-warn-container toast-error-container-after',
        });
    },
    info(msg, options = {}) {
        return toast.info(msg, {
            ...defaultOptions,
            ...options,
            className: 'toast-info-container toast-info-container-after',
        });
    },
};

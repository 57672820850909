import type { ActionProps } from 'shared/interfaces/store/action/actionProps';

import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { ImpactScore } from '@shared/interfaces/store/impactScore';
import axios from 'services/axios/axios';
import { queryBuilder } from 'services/url/url';

export const fetchImpactScore = createAsyncThunkWithClient<ImpactScore, ActionProps>({
    requestFn: (params, config) => {
        const { category = null, ...rest } = params;
        const url                          = queryBuilder(
            category ? `impact/${category}` : 'impact',
            rest,
        );

        return axios.get<ImpactScore>(url, config);
    },
    typePrefix: 'impactScore/score'
});
